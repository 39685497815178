import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Web app enhanced with AI/ML to increase the accuracy of medical tests.',
        title: 'CODI'
    },
    {
        image: 'image-2',
        category: 'Web search engine tailored so that only reliable sources are utilized and unbiased ones promoted.',
        title: 'fookFake.news'
    },
    {
        image: 'image-3',
        category: 'Mobile app targeting general customers to swipe for symptoms to match potential diagnosis.',
        title: 'Busted'
    },
    {
        image: 'image-4',
        category: 'Research project to prove, and implement the usage of salt for sterilizing fabrics at hospitals in developing nations.',
        title: 'Saltzing'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4>{value.title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;