import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Luszol LLC"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Silibrain LLC"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Diagnosticos Medicos de Guatemala, S.A."/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Fotostar, S.A."/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Completa Salud, S.A."/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Dibasa, S.A."/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="Distribuidora Mayoreos del Norte, S.A."/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="Fotos y Conveniencia, S.A."/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;