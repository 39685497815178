import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaMedium, FaGithub} from "react-icons/fa";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Hello World! I am <span>Roberto Baldizon</span>.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const SocialShare = [
    {Social: <FaGithub/> , link: 'https://www.github.com/b0bbybaldi'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/b0bbybaldi'},
    {Social: <FaMedium /> , link: 'https://www.medium.com/b0bbybaldi'},
    {Social: <FaTwitter /> , link: 'https://www.twitter.com/b0bbybaldi'},
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/b0bbybaldi'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/b0bbybaldi'},
]

class PersonalPortfolio extends Component{
    render(){
        let title = 'About Me',
        description = 'I am originally from Guatemala, where I went to high school. Still, I feel comfortable anywhere in the world and think of it as my home. I speak 3 languages fluently, and one conversationally, not considering the programming languages that I also know. I\'m passionate about technology, science, and business applications for generating positive economic and social impact globally. Such background and experience as a businessman, a technology expert, and as an engineer experienced in software & hardware products, has allowed me to build tailored technology solutions worldwide, specifically in the healthcare and energy industries.';
        return(
            <Fragment> 
                <Helmet pageTitle="Roberto Baldizon || @b0bbybaldi" />
                
                <HeaderTwo logo="logo" color="color-black"/>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.jpg" alt="Roberto Baldizon"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <br/>
                                        <h4 className="title"> Find me online </h4>
                                        <div className="social-share-inner">
                                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Companies I am involved with</h2>
                                    <p>Some I have founded, and others just consulted, but for all there is continuos presence in my life.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <Brand branstyle="branstyle--3" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Some of my cool side projects</h2>
                                        <p>Not all of the ventures gain enough traction to become a company or organization, but they remain great ideas which live for the benefit of society.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-3 col-md-6 col-sm-6 col-12" item="4" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;