import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaMedium, FaGithub} from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";

const SocialShare = [
    {Social: <FaGithub/> , link: 'https://www.github.com/b0bbybaldi'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/b0bbybaldi'},
    {Social: <FaMedium /> , link: 'https://www.medium.com/b0bbybaldi'},
    {Social: <FaTwitter /> , link: 'https://www.twitter.com/b0bbybaldi'},
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/b0bbybaldi'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/b0bbybaldi'},
]

class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { color='default-color' } = this.props;
        let logoUrl = <img src="/assets/images/logo/logo.png" alt="Roberto Baldizon" />;
        return(
            <header className={`header-area header-style-two header--transparent ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;